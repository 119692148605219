<template>
  <v-container fluid class="px-6">
    <v-card elevation="2">

      <v-card-title class="px-6 card-header white--text">
        <v-icon class="mr-3 white--text">mdi-account-plus</v-icon> <h3>User Registration Form</h3>
      </v-card-title>
     
      <v-form v-model="isFormValid" ref="userForm">
        <v-container :class="$vuetify.breakpoint.lgAndUp? 'px-10' : 'px-2'" style="max-height: calc(100vh - 190px);overflow-y:auto;">
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-subheader class="pa-1 black--text">First Name :</v-subheader>
              <v-text-field
                v-model="userData.firstName"
                :rules="[
                  () => !!userData.firstName || 'This field is required',
                ]"
                required
                outlined
                dense 
                autocomplete="off"
                
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-subheader class="pa-1 black--text">Last Name :</v-subheader>
              <v-text-field
                v-model="userData.lastName"
                :rules="[() => !!userData.lastName || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Username</v-subheader>
              <v-text-field
                v-model="userData.name"
                :rules="[() => !!userData.name || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Designation</v-subheader>
              <v-text-field
                v-model="userData.designation"
                :rules="[
                  () => !!userData.designation || 'This field is required',
                ]"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Roles</v-subheader>
              <v-select
                v-model="userData.roles"
                :rules="[() => !!userData.roles || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
                :items="userRoles"
                :menu-props="{ bottom: true, offsetY: true }"
                item-value="idUserAccess"
                item-text="userAccessName"
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">State</v-subheader>
              <v-autocomplete
                multiple
                v-model="selectedState"
                :rules="[() => selectedState.length != 0 || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
                :items="listState"
                :menu-props="{ bottom: true, offsetY: true }"
                chips
              >
              
                <template v-slot:prepend-item>
                  <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleSelectStates"
                  >
                    <v-list-item-action>
                      <v-icon :color="selectedState.length > 0 ? 'primary' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Email</v-subheader>
              <v-text-field
                v-model="userData.email"
                :rules="[() => !!userData.email || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Mobile Phone</v-subheader>
              <v-text-field
                v-model="userData.phone"
                required
                outlined
                dense
                name="Math.random()"
                autocomplete="phonenum"
                @keypress="isNumber($event)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Password</v-subheader>
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="userData.password"
                :rules="[() => !!userData.password || 'This field is required']"
                required
                outlined
                dense
                name="Math.random()"
                autocomplete="new-password"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text"
                >Confirm Password
              </v-subheader>
              <v-text-field
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="userData.confirmPword"
                :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                required
                outlined
                dense
                autocomplete="off"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-col>

            <v-subheader class="pa-1 black--text"></v-subheader>
            <v-col cols="12" md="6" class="pb-5 pt-0">

              <v-checkbox
                class="mr-4"
                v-model="userData.alert"
                label="Alert"
              >
              </v-checkbox>
            </v-col>

          </v-row>

          <v-divider></v-divider>

          <v-row class="pb-4 pt-4" justify="center">
              <v-btn 
              class="mr-4 button white--text" 
              @click="postUserData" 
              :loading='loadingbutton'
              :disabled="!isFormValid"
              >
                SUBMIT
              </v-btn>
          </v-row>

          <!---- Message Dialog/Snackbar ---->
          <v-dialog
            v-model="dialogMessage"
            persistent
            width="500"
          >
            <v-card>
              <v-card-title class="text-h5 white--text button">
                <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Form has been submitted
              </v-card-title>

              <v-card-text class="pa-5 d-flex justify-center">
                <h3 class="">User Has Been Added</h3> <v-icon class="mb-1 ml-1">mdi-check-circle-outline</v-icon> 
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="dialogMessage = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogError"
            persistent
            width="500"
          >
            <v-card>
              <v-card-title class="text-h5 white--text error">
                <v-icon class="white--text mr-2">mdi-text-box-remove</v-icon>Form failed to submit
              </v-card-title>

              <v-card-text class="pa-5 d-flex justify-center">
                <h3>{{textError}}</h3>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="dialogError = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </v-form>

    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialogMessage: false,
    dialogError: false,
    textError: "",
    loadingbutton: false,
    showConfirmPassword: false,
    showPassword: false,
    listState: [],
    selectedState: [],
    userData: {
      firstName: "",
      lastName: "",
      name: "",
      designation: "",
      email: "",
      confirmPword: "",
      password: "",
      phone: "",
      roles: "",
      dataValidation: "",
      alert: "",
      idUserAccess: "",
      state: ""
    },
    confirmPasswordRules: [(v) => !!v || "Password is required"],
    isFormValid: false,
    userRoles: [],
    dataValidation: 0,
    alert: 0,
  }),

  mounted() {
    this.getDropdowns();
  },

  methods: {

    getDropdowns(){
      axios
      .get(this.globalUrl+`mqm2/users/alldropdowns`, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
        this.userRoles = response.data.userAccess;
        this.listState = response.data.states;
      })
      .catch((error) => {
        console.log(error);
      });
    },

    isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    },

    toggleSelectStates(){

      this.$nextTick(() => {
        if (this.selectAllStates) {
          this.selectedState = []
        } else {
          this.selectedState = this.listState.slice()
        }
      })

    },

    postUserData() {
      
      this.loadingbutton = true;

      const payload = {
        firstname: this.userData.firstName,
        lastname: this.userData.lastName,
        name: this.userData.name,
        designation: this.userData.designation,
        email: this.userData.email,
        password: this.userData.password,
        phoneNo: this.userData.phone,
        // role: this.userData.roles,
        // dataValidationStatus: this.userData.dataValidation,
        alert1: this.userData.alert == true? '1' : '0',
        idUserAccess: JSON.stringify(this.userData.roles),
        state: this.selectedState.join(',')
      };

      console.log(payload);

      if (payload.firstname != "" && payload.lastname != ""
      && payload.name != "" && payload.designation != "" && payload.email != "" &&
      payload.password != "" && payload.phoneNo != "" && payload.role != "" && payload.password == this.userData.confirmPword) {
        

        axios.post(this.globalUrl+"mqm2/users/add", payload, {
          headers: {
            'Authorization': "Bearer " + this.$store.getters.user.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.dialogMessage = true;
          this.loadingbutton = false;
          this.$refs.userForm.reset();
        })
        .catch((error) => {
          console.log(error.response);

          if(error.response.status == 500){
            this.textError = "Error. Something went wrong. Please try again later."
          }
          else{
            if(error.response.data.message != undefined){
              if(error.response.data.message == ""){
                this.textError = "Error. Something went wrong. Please try again later."
              }
              else if(error.response.data.message == "Email sudahpun dipakai"){
                this.textError = "The email is already in use"
              }
            }
            else{
              this.textError = "Error. Something went wrong. Please try again later."
            }
          }

          this.dialogError = true;
          this.loadingbutton = false;

          
        });
      }
      else{
        // let self = this
        // setTimeout(function () {
        //     if (self.$refs.userForm.validate()){
        //     //other codes
        //     alert('submitted')
        //     }  
        // })
      }

      
    }



  },

  computed: {
    passwordConfirmationRule() {
      return () =>
        this.userData.password === this.userData.confirmPword ||
        "Password must match";
    },
    selectAllStates () {
      return this.selectedState.length === this.listState.length
    },
    selectSomeStates () {
      return this.selectedState.length > 0 && !this.selectAllStates
    },
    icon () {
      if (this.selectAllStates) return 'mdi-close-box'
      if (this.selectSomeStates) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  
};
</script>

 <style lang="scss" scoped>
.v-subheader {
  height: 30px;
}

.v-application--wrap > .v-main{
  padding: 40px 0px 0px !important;
}
</style>